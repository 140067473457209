window.header = function () {
	return {
		menuOpen: false,
		clickedPosition: null,

		toggleMenu() {
			let target = document.querySelector("#main");
			let y = target.getBoundingClientRect().top + window.pageYOffset;
			if (this.menuOpen) {
				if (this.clickedPosition < y) {
					console.log("this is happening");
					this.menuOpen = !this.menuOpen;
					this.$nextTick(() => {
						setTimeout(() => {
							window.scrollTo({
								top: this.clickedPosition,
								behavior: "smooth",
							});
						}, 500);
					});
				} else {
					this.menuOpen = !this.menuOpen;
				}
			} else if (!this.menuOpen) {
				this.clickedPosition = window.pageYOffset;
				if (
					y > document.documentElement.scrollTop ||
					document.body.scrollTop
				) {
					window.scrollTo({ top: y, behavior: "smooth" });
					setTimeout(() => {
						this.menuOpen = !this.menuOpen;
					}, 500);
				} else {
					this.menuOpen = !this.menuOpen;
				}
			}
		},
	};
};
